import React, { useEffect, useState } from 'react';

import { Card, Pagination } from '../components';
import FadeIn from 'react-fade-in';
import { allJobs } from '../utils/Data';
import { client } from '../client';
import { useAuth } from '../contexts/AuthContext';

function JobCardRender(job) {
  const currentDate = new Date();
  const expiryDate = new Date(job.postExpiry);
  const isJobExpired = expiryDate < currentDate;

  return (
    <Card
      key={job._id}
      jobId={job._id}
      link={`/dashboard/update/${job._id}`}
      jobExpired={isJobExpired}
      title={job.title}
      postedOn={job.postedOn}
      postAt={job.postAt}
    />
  );
}

function Jobs() {
  const [currentPage, setCurrentPage] = useState(0);
  const [maxOpenings, setMaxOpenings] = useState();
  const { currentUser } = useAuth();

  const [activeState, setActiveState] = useState(true);
  const [expState, setExpState] = useState(false);

  const [activeJobs, setActiveJobs] = useState([]);
  const [expiredJobs, setExpiredJobs] = useState([]);

  useEffect(() => {
    client
      .fetch(allJobs(currentUser.uid))
      .then((jobs) => {
        const currentDate = new Date();
        const activeJobs = jobs.filter((job) => {
          const expiryDate = new Date(job.postExpiry);
          return expiryDate >= currentDate;
        });
        setActiveJobs(activeJobs);

        const expiredJobs = jobs.filter((job) => {
          const expiryDate = new Date(job.postExpiry);
          return expiryDate < currentDate;
        });
        setExpiredJobs(expiredJobs);

        if (activeState) {
          setMaxOpenings(activeJobs.length);
        } else if (expState) {
          setMaxOpenings(expiredJobs.length);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function activePageinc() {
    if (currentPage < maxOpenings / 10 - 1) setCurrentPage(currentPage + 1);
  }

  function activePagedec() {
    if (currentPage != 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  function getExpiredJobs() {
    setMaxOpenings(expiredJobs.length);
    setCurrentPage(0);
    setActiveState(false);
    setExpState(true);
    document.getElementById('active').classList.toggle('bg-gray-200');
    document.getElementById('expired').classList.toggle('bg-gray-200');
  }

  function getActiveJobs() {
    setMaxOpenings(activeJobs.length);
    setCurrentPage(0);
    setActiveState(true);
    setExpState(false);
    document.getElementById('active').classList.toggle('bg-gray-200');
    document.getElementById('expired').classList.toggle('bg-gray-200');
  }

  const NoJob = () => {
    return (
      <section className="md:px-8 md:mx-8 justify-center flex flex-col items-center mt-16 ">
        <div className=" mb-4 ">
          <p className="font-bold">No Jobs Found</p>
        </div>
      </section>
    );
  };

  return (
    <FadeIn delay={100} transitionDuration={1000} className="scroll-smooth">
      <div className=" flex space-x-4 text-lg md:px-16 md:mx-8 px-2 mx-2 py-8 border-t border-gray-200  text-primary justify-center">
        <button
          id="active"
          onClick={getActiveJobs}
          className=" bg-gray-200 hover:bg-gray-200 px-4 py-1 rounded font-semibold"
        >
          Active Jobs
        </button>
        <button
          id="expired"
          onClick={getExpiredJobs}
          className=" px-4 py-1 rounded font-semibold hover:bg-gray-200 "
        >
          Expired Jobs
        </button>
      </div>
      <section className="md:px-8 md:mx-8 py-4 ">
        <div className="md:px-16 md:mx-16 px-2 mx-2 mb-8 min-h-[42vh]">
          {activeState && !expState && activeJobs.length >= 1
            ? activeJobs
                .slice(currentPage * 10, currentPage * 10 + 10)
                .map(JobCardRender)
            : !activeState && expState && expiredJobs.length >= 1
            ? expiredJobs
                .slice(currentPage * 10, currentPage * 10 + 10)
                .map(JobCardRender)
            : NoJob()}
        </div>

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          increment={activePageinc}
          decrement={activePagedec}
          maxOpenings={maxOpenings}
        />
      </section>
    </FadeIn>
  );
}

export default Jobs;
