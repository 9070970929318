import { useAuth } from '../contexts/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { client } from '../client';
import { jobDetails } from '../utils/Data';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { Signin } from '../components';
import { useNavigate, useParams } from 'react-router-dom';
import blockTools, { normalizeBlock } from '@sanity/block-tools';
import Schema from '@sanity/schema';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import NoAccess from './NoAccess';
import LoadingOverlay from '../components/LoadingOverlay';
import { toHTML } from '@portabletext/to-html';

const UpdateJob = () => {
  const { jobId } = useParams();
  const [verifyEmp, setVerifyEmp] = useState('');
  const [loading, setLoading] = useState(true);
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [salary, setSalary] = useState('');
  const [location, setLocation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [vacancy, setVacancy] = useState('');
  const [category, setCategory] = useState('');
  const [companyBio, setCompanyBio] = useState('');

  useEffect(() => {
    client.fetch(jobDetails(jobId)).then((res) => {
      const jobDetails = res[0];
      setVerifyEmp(jobDetails.employer._id);
      setTitle(jobDetails.title);
      setCompanyName(jobDetails.companyName);
      setEmail(jobDetails.email);
      setSalary(jobDetails.salary);
      setVacancy(jobDetails.vacancies);
      setCity(jobDetails.city);
      setProvince(jobDetails.province);
      setLocation(jobDetails.location);
      setLoading(false);
      setBody(toHTML(jobDetails.body));
      setCompanyBio(toHTML(jobDetails.companyBio));
    });
  }, []);

  const handleDesc = (e) => {
    setBody(e);
  };
  // Context
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Update Profile ********************************************
  // Push Block contents
  const defaultSchema = Schema.compile({
    name: 'myBlog',
    types: [
      {
        type: 'object',
        name: 'blogPost',
        fields: [
          {
            title: 'Title',
            type: 'string',
            name: 'title',
          },
          {
            title: 'Body',
            name: 'body',
            type: 'array',
            of: [{ type: 'block' }],
          },
        ],
      },
    ],
  });

  const blockContentType = defaultSchema
    .get('blogPost')
    .fields.find((field) => field.name === 'body').type;

  const blocks = blockTools.htmlToBlocks(body, blockContentType);
  const readyBlocks = normalizeBlock(blocks, {
    allowedDecorators: ['strong', 'em', 'underline'],
  });
  const bioBlocks = blockTools.htmlToBlocks(companyBio, blockContentType);
  const readyBioBlocks = normalizeBlock(bioBlocks, {
    allowedDecorators: ['strong', 'em', 'underline'],
  });
  async function updateJobDesc(e) {
    e.preventDefault();
    setLoading(true);
    const job = {
      jobId: jobId,
      body: readyBlocks,
      title: title,
      companyName: companyName,
      location: location,
      salary: salary,
      email: email,
      city: city,
      province: province,
      vacancy: vacancy > 0 ? Number(vacancy) : 1,
      category: category,
      companyBio: readyBioBlocks,
    };

    await axios
      .post('/.netlify/functions/updateJob', job)
      .then((response) => {
        setLoading(false);
        //TODO ADD MODAL HERE
        navigate('/dashboard');
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }

  //handle loading state
  if (loading) {
    return <LoadingOverlay />;
  }

  if (!currentUser) {
    return <Signin />;
  } else if (currentUser.uid !== verifyEmp) {
    return <NoAccess />;
  } else {
    return (
      <div className=" flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl w-full space-y-8">
          <button
            onClick={() => navigate(-1)}
            className="bg-primary pr-4 text-lg pl-2 py-1 rounded text-white flex items-center hover:bg-red-800"
          >
            <ChevronLeftIcon className="w-6 h-6" />
            Back
          </button>

          <form onSubmit={updateJobDesc}>
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  {/* location */}
                  <div className="col-span-6 ">
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company Name
                    </label>
                    <input
                      required
                      type="text"
                      name="companyName"
                      id="companyName"
                      value={companyName}
                      onInput={(e) => setCompanyName(e.target.value)}
                      className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Location
                    </label>
                    <input
                      required
                      type="text"
                      name="location"
                      id="location"
                      value={location}
                      onInput={(e) => setLocation(e.target.value)}
                      className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      placeholder="Various/Remote/City "
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 ">
                    <label
                      htmlFor="salary"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email For Application
                    </label>
                    <input
                      required
                      type="text"
                      name="salary"
                      id="salary"
                      autoComplete="salary"
                      value={email}
                      onInput={(e) => setEmail(e.target.value)}
                      className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 ">
                    <label
                      htmlFor="companyBio"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company Profile
                    </label>
                    <div className="mt-1">
                      <ReactQuill
                        value={companyBio}
                        onChange={setCompanyBio}
                        theme="snow"
                        placeholder="Please provide a short bio for your company"
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Brief description for your Company.
                    </p>
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <input
                      required
                      type="text"
                      name="city"
                      id="city"
                      value={city}
                      onInput={(e) => setCity(e.target.value)}
                      className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="province"
                      className="block text-sm font-medium text-gray-700"
                    >
                      State / Province
                    </label>
                    <input
                      required
                      type="text"
                      name="province"
                      id="province"
                      value={province}
                      onInput={(e) => setProvince(e.target.value)}
                      className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="vacancy"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Vacancies
                    </label>
                    <input
                      required
                      type="text"
                      name="vacancy"
                      id="vacancy"
                      value={vacancy}
                      onInput={(e) => setVacancy(e.target.value)}
                      className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  {/* Title */}
                  <div className="col-span-6">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Job Title
                    </label>
                    <input
                      required
                      type="text"
                      name="title"
                      id="title"
                      autoComplete="title"
                      value={title}
                      onInput={(e) => setTitle(e.target.value)}
                      className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Category
                    </label>
                    <select
                      required
                      id="category"
                      name="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="Full-time">Full-time</option>
                      <option value="Part-time">Part-time</option>
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="salary"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Salary
                    </label>
                    <input
                      required
                      type="text"
                      name="salary"
                      id="salary"
                      placeholder="$"
                      autoComplete="salary"
                      value={salary}
                      onInput={(e) => setSalary(e.target.value)}
                      className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  {/* Description */}
                  <div className="col-span-6 ">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Job Description
                    </label>
                    <div className="mt-1">
                      <ReactQuill
                        required
                        value={body}
                        onChange={handleDesc}
                        theme="snow"
                        placeholder="Please provide a short bio for your company"
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Job Description. Please include as much information as you
                      can.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="bg-primary px-4 py-1 mt-4 text-lg rounded text-white flex items-center hover:bg-red-800"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    );
  }
};

export default UpdateJob;
