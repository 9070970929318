export const allJobs = (employerId) => {
  const query = `*[_type=='post' && employer._ref == '${employerId}'] | order(postedOn desc)
      {
        _id, title, postedOn, postAt, postExpiry
      }
      `;

  return query;
};

export const jobDetails = (jobId) => {
  const query = `*[_type=='post' && _id == '${jobId}'  && isActive ==true]
  {
    title,email,salary,city,province,location,categories,body,postedOn,postExpiry,companyName,companyBio,vacancies,employer->{_id}
  }`;

  return query;
};

export const creditsQuery = (empId) => {
  const query = `*[_type=='employer' && _id == '${empId}'] {postCredits}`;
  return query;
};

export const employerInfoQuery = (empId) => {
  const query = `*[_type=='employer' && _id == '${empId}'] {_id,email,name,website,contact,fullAddress,bio}`;
  return query;
};
