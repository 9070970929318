import React, { useEffect, useState } from 'react';
import { DashHeader, Footer, LoadingOverlay } from '../components';
import { useAuth } from '../contexts/AuthContext';
import FadeIn from 'react-fade-in/lib/FadeIn';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { employerInfoQuery } from '../utils/Data';
import { clientNoCdn } from '../clientNoCdn';
import {
  UserCircleIcon,
  GlobeAltIcon,
  LocationMarkerIcon,
  InformationCircleIcon,
  PhoneIcon,
} from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import blockTools, { normalizeBlock } from '@sanity/block-tools';
import Schema from '@sanity/schema';
import axios from 'axios';
import { toHTML } from '@portabletext/to-html';

function Profile() {
  // Context
  const { currentUser, signout, resetPassword } = useAuth();
  const navigate = useNavigate();

  // Current States
  const [employerInfo, setEmployerInfo] = useState({
    _id: '',
    name: '',
    email: '',
    website: '',
    contact: '',
    fullAddress: '',
    bio: [],
  });
  const [loading, setLoading] = useState(true);

  // Retrieve Current employer Information
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [web, setWeb] = useState('');
  const [phone, setPhone] = useState('');
  const [bio, setBio] = useState('');
  useEffect(() => {
    clientNoCdn.fetch(employerInfoQuery(currentUser.uid)).then((res) => {
      setEmployerInfo(res[0]);
      const employerDetails = res[0];
      setName(employerDetails.name);
      setWeb(employerDetails.website);
      setPhone(employerDetails.contact);
      setAddress(employerDetails.fullAddress);
      setBio(toHTML(employerDetails.bio));
      setLoading(false);
    });
  }, []);

  // Updated States

  const handleBio = (e) => {
    setBio(e);
  };

  // Signout handle
  function handleSignout(e) {
    try {
      signout();
      navigate('/');
    } catch {
      alert('Error: Failed to Sign out.');
    }
  }

  // ChangePassBtnClick
  function changePassBtnClick() {
    try {
      resetPassword(currentUser.email);
      alert('We have emailed you a link to change your password. Thank you!');
    } catch (err) {
      alert(err);
    }
  }

  // Push Block contents
  const defaultSchema = Schema.compile({
    name: 'myBlog',
    types: [
      {
        type: 'object',
        name: 'blogPost',
        fields: [
          {
            title: 'Title',
            type: 'string',
            name: 'title',
          },
          {
            title: 'Body',
            name: 'body',
            type: 'array',
            of: [{ type: 'block' }],
          },
        ],
      },
    ],
  });

  const blockContentType = defaultSchema
    .get('blogPost')
    .fields.find((field) => field.name === 'body').type;

  const blocks = blockTools.htmlToBlocks(bio, blockContentType);
  const readyBlocks = normalizeBlock(blocks, {
    allowedDecorators: ['strong', 'em'],
  });

  async function updateProfile(e) {
    setLoading(true);
    e.preventDefault();
    const employer = {
      employerId: currentUser.uid,
      name: name,
      website: web,
      contact: phone,
      fullAddress: address,
      bio: readyBlocks,
    };

    await axios
      .post('/.netlify/functions/updateProfile', employer)
      .then((response) => {
        alert(
          'Your profile has been succesfully Updated, It may take a few seconds to show the update. Try refreshing in a few seconds if you do not see the updates right away.'
        );
        console.log('Success, Profile Updated!');
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // *************************************************************

  if (loading) {
    return <LoadingOverlay />;
  }
  return (
    <>
      <DashHeader handle={handleSignout} />
      <div className="px-4 mx-4 flex flex-col sm:flex-row justify-center lg:justify-end items-center mt-4 mb-8">
        <div className="flex sm:flex-row flex-col">
          <button
            onClick={changePassBtnClick}
            className="px-4 my-1 py-1 mx-2 bg-white font-semibold text-primary border-2 border-primary hover:text-white hover:bg-primary ease-in duration-75 rounded-md"
          >
            Change Password
          </button>
        </div>
      </div>
      <FadeIn>
        <div className=" flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl w-full space-y-8">
            <form className="mb-16" onSubmit={updateProfile}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md">
                <div>
                  <label
                    htmlFor="name"
                    className="font-bold flex items-center  text-primary"
                  >
                    <UserCircleIcon className="text-primary h-5 w-5 mr-2 " />
                    Employer Name:
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    className="my-2 font-semibold appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                    placeholder="Employer Name"
                    value={name}
                    onInput={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="website"
                    className="font-bold flex items-center  text-primary"
                  >
                    <GlobeAltIcon className="text-primary h-5 w-5 mr-2 " />
                    Website:
                  </label>
                  <input
                    id="web"
                    name="web"
                    type="text"
                    required
                    className="my-2 font-semibold appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                    placeholder="Website, Please type 'N/A' if you do not have a website"
                    value={web}
                    onInput={(e) => setWeb(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="font-bold flex items-center  text-primary"
                  >
                    <PhoneIcon className="text-primary h-5 w-5 mr-2 " />
                    Phone:
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    required
                    className="my-2 font-semibold appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                    placeholder="Phone, Please type 'N/A' if you do not wish to provide a number"
                    value={phone}
                    onInput={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="font-bold flex items-center  text-primary"
                  >
                    <LocationMarkerIcon className="text-primary  h-5 w-5 mr-2 " />
                    Address:
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    required
                    className="my-2 font-semibold appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                    placeholder="Full Address"
                    value={address}
                    onInput={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="font-bold flex items-center  text-primary mb-2"
                  >
                    <InformationCircleIcon className="text-primary h-5 w-5 mr-2 " />
                    Bio:
                  </label>
                  <ReactQuill
                    theme="snow"
                    placeholder="Please provide a short bio for your company"
                    value={bio}
                    onChange={handleBio}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="bg-primary px-4 py-1 mt-4 text-lg rounded text-white flex items-center hover:bg-red-800"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      </FadeIn>

      <Footer />
    </>
  );
}

export default Profile;
