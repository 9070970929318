import React, { useRef, useState } from 'react';
import {
  LockClosedIcon,
  SearchCircleIcon,
  UserCircleIcon,
} from '@heroicons/react/solid';
import { useAuth } from '../contexts/AuthContext';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Link, useNavigate } from 'react-router-dom';
import Landingfooter from './Landingfooter';
import Verify from './Verify';

export default function Signin() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { currentUser, signin } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await signin(emailRef.current.value, passwordRef.current.value).then(
        (user) => {
          const activeUser = user.user;
          if (activeUser.emailVerified) {
            navigate('/dashboard');
          } else {
            navigate('/create-profile');
          }
        }
      );
    } catch (err) {
      alert('Something went wrong, Please try again later!');
      setLoading(false);
    }
  }

  if (!currentUser) {
    return (
      <>
        <FadeIn>
          <div className="min-h-full flex items-center justify-center py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <div>
                <div className="flex items-center justify-center">
                  <SearchCircleIcon className="text-primary mr-2 w-10 h-10" />
                  <h1 className="text-center font-extrabold font-[Poppins] text-2xl md:text-4xl text-primary my-2 ">
                    Canada Job Leads
                  </h1>
                </div>

                <h2 className="mt-6 text-center text-xl md:text-2xl font-bold text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Haven't got an account?{' '}
                  <Link
                    to="/sign-up"
                    className="font-bold text-primary hover:text-red-900"
                  >
                    Sign up
                  </Link>
                </p>
              </div>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Email address"
                      ref={emailRef}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Password"
                      ref={passwordRef}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-start">
                  <div className="text-sm">
                    <Link
                      to="/forgot-password"
                      className="font-medium text-primary hover:text-red-900"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    disabled={loading}
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-red-900 ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon
                        className="h-5 w-5 text-red-400 group-hover:text-red-200 ease-in duration-100"
                        aria-hidden="true"
                      />
                    </span>
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </FadeIn>
        <Landingfooter />
      </>
    );
  }
  return (
    <>
      <div className="flex justify-center px-4 py-16 lg:py-60">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <UserCircleIcon
                  className="h-6 w-6 text-primary"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Welcome Back!
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    You are already logged in.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <Link to="/dashboard">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Go to Dashboard
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Landingfooter />
    </>
  );
}
