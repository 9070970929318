import React, { useRef, useState } from 'react';
import { LockClosedIcon, SearchCircleIcon } from '@heroicons/react/solid';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Landingfooter from './Landingfooter';

function Forgot() {
  const emailRef = useRef();
  const { currentUser, resetPassword } = useAuth();
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await resetPassword(emailRef.current.value);

      alert(
        'If an account with this email address exists, we will send you an email to reset your password.'
      );
    } catch (err) {
      alert('Something went wrong, please try again later!');
    }

    setLoading(false);
  }

  if (!currentUser) {
    return (
      <>
        <FadeIn>
          <div className="min-h-full flex items-center justify-center md:py-42 py-28 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <div>
                <div className="flex items-center justify-center">
                  <SearchCircleIcon className="text-primary mr-2 w-10 h-10" />
                  <h1 className="text-center font-extrabold font-[Poppins] text-2xl md:text-4xl text-primary my-2 ">
                    Canada Job Leads
                  </h1>
                </div>

                <h2 className="mt-6 text-center text-xl md:text-2xl font-bold text-gray-900">
                  Enter your email
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  <Link
                    to="/"
                    className="font-bold text-primary hover:text-red-900"
                  >
                    Back to Sign in
                  </Link>
                </p>
              </div>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      ref={emailRef}
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none  relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Email address"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-red-900 ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon
                        className="h-5 w-5 text-red-400 group-hover:text-red-200 ease-in duration-100"
                        aria-hidden="true"
                      />
                    </span>
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </FadeIn>
        <Landingfooter />
      </>
    );
  }

  return (
    <>
      <p className="mt-6 text-center text-xl md:text-2xl font-bold text-gray-900">
        You are already logged in!
      </p>
      <Link to="/dashboard">
        <button className=" mt-4 flex justify-center py-2 px-4 mx-auto border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-red-900 ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Go to Dashboard
        </button>
      </Link>
      <Landingfooter />
    </>
  );
}

export default Forgot;
