import React from "react";

import "./App.css";
import {Landing} from "./pages";


function App() {
  return (
    <>
      <Landing />
    </>
  );
}

export default App;
