import sanityClient from '@sanity/client';



export const clientNoCdn = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2022-02-12',
  useCdn: false,
  token: process.env.REACT_APP_SANITY_VIEW,
  ignoreBrowserTokenWarning: true
});