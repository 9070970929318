import React from "react";
import { Link } from "react-router-dom";

import { Fragment, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import { MenuIcon, SearchCircleIcon, XIcon } from "@heroicons/react/solid";

const navigation = {
  pages: [
    { name: "Dashboard", link: "/dashboard" },
    { name: "Profile", link: "/profile" },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header({ handle }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                {navigation.pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <Link
                      onClick={() => setOpen(false)}
                      to={page.link}
                      className="-m-2 p-2 block font-medium text-gray-900"
                    >
                      {page.name}
                    </Link>
                  </div>
                ))}
              </div>

              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                <div className="flow-root">
                  <button
                    onClick={handle}
                    className="text-md font-medium font-[Poppins] text-white bg-primary px-4 py-2 rounded-lg border-2 border-primary hover:bg-white hover:text-primary ease-in duration-150"
                    type="button"
                  >
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className="relative bg-white">
        <nav aria-label="Top" className=" py-4 mx-auto px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200">
            <div className="h-16 flex items-center">
              <button
                type="button"
                className="bg-white p-2 rounded-md text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="sm:ml-4  lg:ml-0">
                  <Link to="/dashboard" className="flex items-center">
                  <SearchCircleIcon
                    className="sm:h-10 h-8 sm:w-10 w-8 text-primary "
                    aria-hidden="true"
                  />
                  <p className="text-primary font-bold font-[Poppins] ml-2 text-2xl">
                    Canadajobleads
                  </p>
                  </Link>
               
              </div>

              {/* menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="h-full flex space-x-8">
                  {navigation.pages.map((page) => (
                    <Link
                      className="flex cursor-pointer items-center text-md font-medium text-gray-700 hover:text-gray-800 active:border-b-2 hover:border-b-2 border-primary"
                      key={page.name}
                      to={page.link}
                    >
                      {page.name}
                    </Link>
                  ))}
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6 ">
                  <button
                    onClick={handle}
                    className="text-md font-medium  text-white bg-primary px-4 py-1 rounded-lg border-2 border-primary hover:bg-white hover:text-primary ease-in duration-150"
                    type="button"
                  >
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
