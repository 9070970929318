import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import {
  DashHeader,
  Signin,
  Verify,
  Footer,
  Jobs,
  LoadingOverlay,
} from '../components';
import { creditsQuery } from '../utils/Data';
import { clientNoCdn } from '../clientNoCdn';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

function Dashboard() {
  const { currentUser, signout } = useAuth();
  const [error, setError] = useState('');
  const [credits, setCredits] = useState(0);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  function handleSignout(e) {
    setError('');

    try {
      signout();
      navigate('/');
    } catch {
      alert('Error: Failed to Sign out.');
    }
  }

  useEffect(() => {
    clientNoCdn
      .fetch(creditsQuery(currentUser?.uid))
      .then((res) => {
        setCredits(res[0].postCredits || 0);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [currentUser]);

  if (loading) {
    return <LoadingOverlay />;
  }
  if (!currentUser) {
    return <Signin />;
  } else if (currentUser && !currentUser.emailVerified) {
    return (
      <>
        <DashHeader handle={handleSignout} />

        <div className="flex justify-center px-4 py-16 lg:py-60">
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationCircleIcon
                    className="h-6 w-6 text-primary"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Action Required
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Please verify your email and refresh the page. If you
                      haven't created your profile yet, click below to continue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <Link to="/create-profile">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Create Profile
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DashHeader handle={handleSignout} />
      <div className="px-4 mx-4 flex flex-col sm:flex-row justify-center lg:justify-end items-center mt-4 mb-8">
        <div>
          <p className="sm:mr-6 sm:pr-6 border-r border-gray-300 mr-0 mb-4 sm:mb-0 font-semibold  text-gray-800">
            <span className="font-bold mr-2 border-2 text-lg border-primary rounded px-4 py-1 text-primary ">
              {credits}
            </span>{' '}
            Available Post Credits
          </p>
        </div>

        <div className="flex">
          <Link to="/buy-credits">
            <button className="px-4 py-1 mr-4 bg-primary font-semibold text-white border-2 border-primary hover:text-primary hover:bg-white ease-in duration-75 rounded-md">
              Buy More Credits
            </button>
          </Link>
          <Link to="/post-job">
            <button className="px-4 py-1 bg-white font-semibold text-primary border-2 border-primary hover:text-white hover:bg-primary ease-in duration-75 rounded-md">
              Post a Job
            </button>
          </Link>
        </div>
      </div>

      <Jobs />
      <Footer />
    </>
  );
}

export default Dashboard;
