import React from 'react'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'
import Landingfooter from './Landingfooter'

function NotFound() {
  
  return (
    <>
    <div className='h-full px-4 mx-4 my-16'>
        <Link to="/dashboard"><button >
          <div className="text-lg font-semibold text-gray-900 flex flex-row items-center">
            <ChevronLeftIcon className="text-gray-900 h-5 w-5" />
            <h1>Home</h1>
          </div>
        </button></Link>
        <h1 className='text-center mx-auto my-64 font-[Poppins] font-bold text-2xl text-gray-900'>404 | Oops! the file you are looking for doesn't exist or has moved.</h1>
    </div>
    <Landingfooter />
    </>
  )
}

export default NotFound