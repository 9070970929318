import { CalendarIcon, PencilIcon, LinkIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

const PostAtBtn = ({ website, jobId }) => {
  return (
    <a href={`https://${website}.ca/jobs/${jobId}`} target="_blank">
      <button
        type="button"
        className="inline-flex my-2 items-center mx-1 px-4 py-2 border border-primary ease-in duration-100 hover:bg-red-100 rounded-md shadow-sm text-sm font-medium text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      >
        <LinkIcon
          className="-ml-1 mr-2 h-5 w-5 text-primary"
          aria-hidden="true"
        />
        {website}
      </button>
    </a>
  );
};

export default function Card({
  jobId,
  title,
  postedOn,
  postAt,
  link,
  jobExpired,
}) {
  return (
    <>
      <div className="flex flex-wrap -m-4 ">
        <div className="w-full mb-4 ">
          <div className="border border-gray-200 p-4 rounded-lg bg-gray-100 mb-2 mx-4">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="flex-1 min-w-0">
                <h2 className="text-xl font-bold leading-7 text-gray-900  sm:truncate">
                  {title}
                </h2>
                <div className="mt-1 flex flex-col  sm:flex-row sm:flex-wrap sm:mt-0">
                  <div className="mt-2 flex items-center text-sm text-gray-500 mr-4">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary"
                      aria-hidden="true"
                    />
                    Posted on {postedOn}
                  </div>

                  {/* Show Edit Button Only if the job is active */}
                  {!jobExpired && (
                    <div className="mt-2 flex items-center  text-sm text-gray-500">
                      <PencilIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-primary"
                        aria-hidden="true"
                      />

                      <Link
                        to={link}
                        className="font-bold hover:underline hover:text-primary"
                      >
                        Edit Job Description
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5 lg:w-3/5 flex flex-wrap lg:justify-end lg:mt-0 lg:ml-4">
                {postAt.map((website) => {
                  return (
                    <PostAtBtn key={website} website={website} jobId={jobId} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
