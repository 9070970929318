import React, { useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { LockClosedIcon, SearchCircleIcon } from '@heroicons/react/solid';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Landingfooter from './Landingfooter';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import blockTools, { normalizeBlock } from '@sanity/block-tools';
import Schema from '@sanity/schema';
import Signin from './Signin';

function CreateProfile() {
  const { currentUser, signout, verifyEmail } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [web, setWeb] = useState('');
  const [phone, setPhone] = useState('');
  const [bio, setBio] = useState('');

  // Push Block contents
  const defaultSchema = Schema.compile({
    name: 'myBlog',
    types: [
      {
        type: 'object',
        name: 'blogPost',
        fields: [
          {
            title: 'Title',
            type: 'string',
            name: 'title',
          },
          {
            title: 'Body',
            name: 'body',
            type: 'array',
            of: [{ type: 'block' }],
          },
        ],
      },
    ],
  });

  const blockContentType = defaultSchema
    .get('blogPost')
    .fields.find((field) => field.name === 'body').type;

  //   Handle Signout
  function handleSignout(e) {
    try {
      signout();
      navigate('/');
    } catch {
      alert('Error: Failed to Sign out.');
    }
  }

  //   Handle Submit
  async function handleSubmit(e) {
    e.preventDefault();
    alert(
      'A verification email has been sent to your email account. Follow the link provided in the email to verify your account and continue.Thank you!'
    );
    verifyEmail();

    const blocks = blockTools.htmlToBlocks(bio, blockContentType);
    const readyBlocks = normalizeBlock(blocks, {
      allowedDecorators: ['strong', 'em'],
    });

    const employer = {
      _id: currentUser.uid,
      _type: 'employer',
      email: currentUser.email,
      name: name,
      website: web,
      contact: phone,
      fullAddress: address,
      bio: readyBlocks,
      postCredits: 0,
    };

    await axios
      .post('/.netlify/functions/createEmployer', employer)
      .then((response) => {
        console.log('Success, Profile Created!');
        navigate('/dashboard');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  if (!currentUser) {
    return <Signin />;
  } else {
    return (
      <>
        <FadeIn>
          <div className="min-h-full flex items-center justify-center py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg w-full space-y-8">
              <div>
                <div className="flex items-center justify-center">
                  <SearchCircleIcon className="text-primary mr-2 w-10 h-10" />
                  <h1 className="text-center font-extrabold font-[Poppins] text-2xl md:text-4xl text-primary my-2 ">
                    Canada Job Leads
                  </h1>
                </div>
              </div>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Employer Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      required
                      className="rounded-t-md appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Employer Name"
                      value={name}
                      onInput={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Website
                    </label>
                    <input
                      id="web"
                      name="web"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Website, Please type 'N/A' if you do not have a website"
                      value={web}
                      onInput={(e) => setWeb(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Phone
                    </label>
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Phone, Please type 'N/A' if you do not wish to provide a number"
                      value={phone}
                      onInput={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Address
                    </label>
                    <input
                      id="address"
                      name="address"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Full Address"
                      value={address}
                      onInput={(e) => setAddress(e.target.value)}
                    />
                  </div>

                  <div>
                    <label htmlFor="password" className="sr-only">
                      Bio
                    </label>

                    <ReactQuill
                      placeholder="Please provide a short bio for your company"
                      value={bio}
                      onChange={setBio}
                    />
                  </div>
                </div>

                <div>
                  <button
                    disabled={loading}
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-primary hover:bg-red-900 ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Create Profile & Verify
                  </button>
                  <button
                    onClick={handleSignout}
                    type="submit"
                    className="mt-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-primary hover:bg-red-900 ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Sign Out
                  </button>
                </div>
              </form>
            </div>
          </div>
        </FadeIn>
        <Landingfooter />
      </>
    );
  }
}

export default CreateProfile;
