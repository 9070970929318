import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate } from 'react-router-dom';
import { client } from '../client';
import { DashHeader, Footer, LoadingOverlay, Signin } from '../components';
import { useAuth } from '../contexts/AuthContext';
import { creditsQuery } from '../utils/Data';
import blockTools, { normalizeBlock } from '@sanity/block-tools';
import Schema from '@sanity/schema';
import axios from 'axios';
import { clientNoCdn } from '../clientNoCdn';

// TODO POST JOB LAMBDA FUNCTION
// CREATE A PUBLISHED STATE

function Postjob() {
  const { currentUser, signout } = useAuth();
  const navigate = useNavigate();
  const [credits, setCredits] = useState(0);

  //   SIGNOUT HANDLE
  function handleSignout(e) {
    try {
      signout();
      navigate('/');
    } catch {
      alert('Error: Failed to Sign out.');
    }
  }

  //   States for elements.

  // Page states
  const [defaultState, setDefaultState] = useState(true);
  const [successState, setSuccessState] = useState(false);

  //   Post Another click function
  function postAnother() {
    setDefaultState(true);
    setSuccessState(false);
  }

  // ***************Handle Post Submit************************
  const [title, setTitle] = useState('');

  const [salary, setSalary] = useState('');
  const [location, setLocation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [vacancy, setVacancy] = useState('');
  const [body, setBody] = useState('');
  const [companyBio, setCompanyBio] = useState('');
  const [loading, setLoading] = useState(true);

  //   Post At IDs
  const newJobs = document.getElementById('NewJL');
  const youthJobs = document.getElementById('YouthJL');
  const ableJobs = document.getElementById('AbleJL');
  const refugeeJobs = document.getElementById('RefugeeJL');
  const aboriginalJobs = document.getElementById('AboriginalJL');
  const categorydropdown = document.getElementById('category');

  //   Set selected category
  function setSelectCategory() {
    const selection =
      categorydropdown.options[categorydropdown.selectedIndex].value;
    return selection;
  }

  //   random id generator
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
  // get current Date
  function currentDate() {
    return new Date().toISOString().slice(0, 10);
  }

  // get expiry Date
  function expiryDate() {
    let today = new Date();
    return new Date(today.getTime() + 91 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10);
  }

  //   Normalize and store body blocks
  // Push Block contents************************************
  const defaultSchema = Schema.compile({
    name: 'myBlog',
    types: [
      {
        type: 'object',
        name: 'blogPost',
        fields: [
          {
            title: 'Title',
            type: 'string',
            name: 'title',
          },
          {
            title: 'Body',
            name: 'body',
            type: 'array',
            of: [{ type: 'block' }],
          },
        ],
      },
    ],
  });

  const blockContentType = defaultSchema
    .get('blogPost')
    .fields.find((field) => field.name === 'body').type;
  // ***********************************************

  async function handlePostSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setDefaultState(false);
    let postAt = [];
    // Push PostAt based on user Input
    if (newJobs.checked) {
      postAt.push('NewJobLeads');
    } else if (!newJobs.checked) {
      postAt.filter((e) => e !== 'NewJobLeads');
    }

    if (youthJobs.checked == true) {
      postAt.push('YouthJobLeads');
    } else if (!newJobs.checked) {
      postAt.filter((e) => e !== 'YouthJobLeads');
    }

    if (ableJobs.checked == true) {
      postAt.push('AbleJobLeads');
    } else if (!newJobs.checked) {
      postAt.filter((e) => e !== 'AbleJobLeads');
    }

    if (refugeeJobs.checked == true) {
      postAt.push('RefugeeJobLeads');
    } else if (!newJobs.checked) {
      postAt.filter((e) => e !== 'RefugeeJobLeads');
    }

    if (aboriginalJobs.checked == true) {
      postAt.push('AboriginalJobLeads');
    } else if (!newJobs.checked) {
      postAt.filter((e) => e !== 'AboriginalJobLeads');
    }

    // check if enough credit balance

    if (postAt.length < 1) {
      alert('Please Select Atleast 1 Job Board.');
      setLoading(false);
    } else if (postAt.length > credits) {
      alert(
        `You do not have enough post credits to publish at ${postAt.length} Job Boards. Please purchase more before continuing or reduce the number of Job Boards you are posting at.`
      );
      setLoading(false);
    } else {
      // Body blocks
      const bodyBlocks = blockTools.htmlToBlocks(body, blockContentType);
      const bioBlocks = blockTools.htmlToBlocks(companyBio, blockContentType);
      const readyBodyBlocks = normalizeBlock(bodyBlocks, {
        allowedDecorators: ['strong', 'em', 'underline'],
      });
      const readyBioBlocks = normalizeBlock(bioBlocks, {
        allowedDecorators: ['strong', 'em', 'underline'],
      });

      const cityFormatted = city.toLowerCase();
      // post document
      const post = {
        _id: uuidv4(),
        _type: 'post',
        location: location,
        companyName: companyName,
        companyBio: readyBioBlocks,
        vacancies: vacancy > 0 ? Number(vacancy) : 1,
        categories: setSelectCategory(),
        title: title,
        salary: salary,
        city: cityFormatted[0].toUpperCase() + cityFormatted.substring(1), //capitalize it rightly before passing.
        province: province,
        email: email,
        body: readyBodyBlocks,
        employer: {
          _ref: currentUser.uid,
          _type: 'reference',
        },
        postedOn: currentDate(),
        postAt: postAt,
        postExpiry: expiryDate(),
        isActive: true,
      };

      const creditInfo = {
        employerId: currentUser.uid,
        creditsUsed: postAt.length,
        jobId: post._id,
      };

      // POST DATA TO BACKEND
      await axios
        .post('/.netlify/functions/decrementCredits', creditInfo)
        .then((res) => {
          setCredits(res.postCredits);
          console.log('Success!');
        })
        .catch((err) => {
          console.error(err);
        });

      await axios
        .post('/.netlify/functions/createPost', post)
        .then((response) => {
          setSuccessState(true);
          setLoading(false);
          console.log('Success!');
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  // *********************************************

  //   fetch current available post credits
  useEffect(() => {
    clientNoCdn
      .fetch(creditsQuery(currentUser?.uid))
      .then((res) => {
        if (res) setCredits(res[0].postCredits || 0);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [successState, currentUser]);

  if (loading) {
    return <LoadingOverlay />;
  }
  if (!currentUser) {
    return <Signin />;
  } else {
    return (
      <>
        <DashHeader handle={handleSignout} />

        {/* Default post state */}
        {defaultState && (
          <>
            <div className="px-4 mx-4 flex flex-col sm:flex-row justify-center lg:justify-end items-center mt-4 mb-8">
              <div>
                <p className="sm:mr-6 sm:pr-6 md:border-r border-gray-300 mr-0 mb-4 sm:mb-0 font-semibold  text-gray-800">
                  <span className="font-bold mr-2 border-2 text-lg border-primary rounded px-4 py-1 text-primary ">
                    {credits}
                  </span>{' '}
                  Available Post Credits
                </p>
              </div>

              <div className="flex">
                <Link to="/buy-credits">
                  <button className="px-4 py-1 mr-4 bg-primary font-semibold text-white border-2 border-primary hover:text-primary hover:bg-white ease-in duration-75 rounded-md">
                    Buy More Credits
                  </button>
                </Link>
              </div>
            </div>
            <div className="sm:px-4 sm:mx-4 xl:px-32 xl:mx-32 py-2 sm:py-16 ">
              {/* Location Information */}
              <div className="mt-10 sm:mt-0">
                <div className="xl:grid xl:grid-cols-3 xl:gap-6">
                  <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                      <h3 className="text-lg text-center xl:text-left font-bold text-primary leading-6 ">
                        Job Description
                      </h3>
                      <p className="mt-1 text-center xl:text-left text-sm text-gray-900">
                        Please fill in the job details.
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 md:mt-0 md:col-span-2">
                    <form onSubmit={handlePostSubmit}>
                      <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                          <div className="grid grid-cols-6 gap-6">
                            {/* location */}
                            <div className="col-span-6 ">
                              <label
                                htmlFor="companyName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Company Name
                              </label>
                              <input
                                required
                                type="text"
                                name="companyName"
                                id="companyName"
                                value={companyName}
                                onInput={(e) => setCompanyName(e.target.value)}
                                className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="location"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Location
                              </label>
                              <input
                                type="text"
                                name="location"
                                id="location"
                                value={location}
                                onInput={(e) => setLocation(e.target.value)}
                                className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                placeholder="Various/Remote/City (Optional) "
                              />
                            </div>
                            <div className="col-span-6 sm:col-span-3 ">
                              <label
                                htmlFor="salary"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email For Application
                              </label>
                              <input
                                required
                                type="text"
                                name="salary"
                                id="salary"
                                autoComplete="salary"
                                value={email}
                                onInput={(e) => setEmail(e.target.value)}
                                className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 ">
                              <label
                                htmlFor="companyBio"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Company Profile
                              </label>
                              <div className="mt-1">
                                <ReactQuill
                                  value={companyBio}
                                  onChange={setCompanyBio}
                                  theme="snow"
                                  placeholder="Please provide a short bio for your company"
                                />
                              </div>
                              <p className="mt-2 text-sm text-gray-500">
                                Brief description for your Company.
                              </p>
                            </div>

                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                              <label
                                htmlFor="city"
                                className="block text-sm font-medium text-gray-700"
                              >
                                City
                              </label>
                              <input
                                required
                                type="text"
                                name="city"
                                id="city"
                                value={city}
                                onInput={(e) => setCity(e.target.value)}
                                className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                              <label
                                htmlFor="province"
                                className="block text-sm font-medium text-gray-700"
                              >
                                State / Province
                              </label>
                              <input
                                required
                                type="text"
                                name="province"
                                id="province"
                                value={province}
                                onInput={(e) => setProvince(e.target.value)}
                                className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                              <label
                                htmlFor="vacancy"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Vacancies
                              </label>
                              <input
                                required
                                type="text"
                                name="vacancy"
                                id="vacancy"
                                value={vacancy}
                                onInput={(e) => setVacancy(e.target.value)}
                                className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            {/* Title */}
                            <div className="col-span-6">
                              <label
                                htmlFor="title"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Job Title
                              </label>
                              <input
                                required
                                type="text"
                                name="title"
                                id="title"
                                autoComplete="title"
                                value={title}
                                onInput={(e) => setTitle(e.target.value)}
                                className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            {/* Job Category */}
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="category"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Category
                              </label>
                              <select
                                required
                                id="category"
                                name="category"
                                onChange={setSelectCategory}
                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              >
                                <option value="Full-time">Full-time</option>
                                <option value="Part-time">Part-time</option>
                              </select>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="salary"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Salary
                              </label>
                              <input
                                required
                                type="text"
                                name="salary"
                                id="salary"
                                placeholder="$"
                                autoComplete="salary"
                                value={salary}
                                onInput={(e) => setSalary(e.target.value)}
                                className="mt-1 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                              />
                            </div>

                            {/* Description */}
                            <div className="col-span-6 ">
                              <label
                                htmlFor="about"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Job Description
                              </label>
                              <div className="mt-1">
                                <ReactQuill
                                  value={body}
                                  onChange={setBody}
                                  theme="snow"
                                  placeholder="Please provide a short bio for your company"
                                />
                              </div>
                              <p className="mt-2 text-sm text-gray-500">
                                Job Description. Please include as much
                                information as you can.{' '}
                              </p>
                            </div>

                            <fieldset className="col-span-6 ">
                              <legend className="text-base font-medium text-gray-900">
                                Publish at: (Choose all Job boards where you
                                want to publish, each selection will cost 1
                                credit.)
                              </legend>
                              <div className="mt-4 space-y-4">
                                <div className="flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="NewJL"
                                      name="NewJobLeads"
                                      type="checkbox"
                                      className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="NewJobLeads"
                                      className="font-medium text-gray-700"
                                    >
                                      NewJobLeads
                                    </label>
                                  </div>
                                </div>
                                <div className="flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="AboriginalJL"
                                      name="AboriginalJobLeads"
                                      type="checkbox"
                                      className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="AboriginalJobLeads"
                                      className="font-medium text-gray-700"
                                    >
                                      AboriginalJobLeads
                                    </label>
                                  </div>
                                </div>
                                <div className="flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="YouthJL"
                                      name="YouthJobLeads"
                                      type="checkbox"
                                      className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="YouthJobLeads"
                                      className="font-medium text-gray-700"
                                    >
                                      YouthJobLeads
                                    </label>
                                  </div>
                                </div>
                                <div className="flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="AbleJL"
                                      name="AbleJobLeads"
                                      type="checkbox"
                                      className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="AbleJobLeads"
                                      className="font-medium text-gray-700"
                                    >
                                      AbleJobLeads
                                    </label>
                                  </div>
                                </div>
                                <div className="flex items-start">
                                  <div className="flex items-center h-5">
                                    <input
                                      id="RefugeeJL"
                                      name="RefugeeJobLeads"
                                      type="checkbox"
                                      className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm">
                                    <label
                                      htmlFor="RefugeeJobLeads"
                                      className="font-medium text-gray-700"
                                    >
                                      RefugeeJobLeads
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <legend className="text-base font-medium text-primary mt-8 mb-4">
                                Please review your information and job details
                                carefully. Once you click 'Publish,' your
                                changes cannot be undone. Each job board you
                                publish will consume 1 credit. If you are ready
                                to proceed, please check the box below to
                                confirm that you have read and understood the
                                statement.
                              </legend>

                              <div className="flex items-start">
                                <div className="flex items-center h-5">
                                  <input
                                    required
                                    id="comments"
                                    name="comments"
                                    type="checkbox"
                                    className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="comments"
                                    className="font-medium text-gray-700"
                                  >
                                    I confirm that I have reviewed the
                                    information above and understand it.
                                  </label>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                      <button
                        //   onClick={publishBtn}
                        disabled={loading}
                        type="submit"
                        className="bg-primary ml-4 sm:ml-0 px-4 py-1 my-4 rounded text-white text-lg flex items-center hover:bg-red-800"
                      >
                        Publish
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* success state */}
        {successState && (
          <div className="flex justify-center px-4 py-16 lg:py-60">
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <CheckCircleIcon
                      className="h-6 w-6 text-primary"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Post Published Successfully.
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Your post has been submitted to our system, after it
                        passes our quality check it will show on your dashboard
                        and at the job boards you selected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <Link to="/dashboard">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Go to Dashboard
                  </button>
                </Link>
                <button
                  onClick={postAnother}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Post Another Job
                </button>
              </div>
            </div>
          </div>
        )}

        <Footer />
      </>
    );
  }
}

export default Postjob;
