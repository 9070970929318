import { Signin, Signup, Forgot, NotFound, CreateProfile } from '../components';

import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Terms from './Terms';
import { AuthProvider, useAuth } from '../contexts/AuthContext';
import Dashboard from './Dashboard';
import Profile from './Profile';
import Postjob from './Postjob';
import BuyCredits from './BuyCredits';
import Success from './Success';
import UpdateJob from './UpdateJob';
import NoAccess from './NoAccess';

function Landing() {
  return (
    <>
      <Router>
        <AuthProvider>
          <Routes>
            <Route exact path="/sign-up" element={<Signup />}></Route>

            <Route exact path="/" element={<Signin />}></Route>

            <Route exact path="/terms" element={<Terms />}></Route>

            <Route exact path="/forgot-password" element={<Forgot />}></Route>

            <Route exact path="/dashboard" element={<Dashboard />}></Route>
            <Route
              exact
              path="/dashboard/update/:jobId"
              element={<UpdateJob />}
            ></Route>
            <Route exact path="/dashboard/*" element={<Dashboard />}></Route>

            <Route exact path="/profile" element={<Profile />}></Route>
            <Route exact path="/profile/*" element={<Profile />}></Route>

            <Route
              exact
              path="/create-profile"
              element={<CreateProfile />}
            ></Route>

            <Route exact path="/post-job" element={<Postjob />}></Route>
            <Route exact path="/buy-credits" element={<BuyCredits />}></Route>
            <Route exact path="/success" element={<Success />}></Route>

            <Route exact path="/no-access" element={<NoAccess />}></Route>

            <Route exact path="*" element={<NotFound />}></Route>
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
}

export default Landing;
