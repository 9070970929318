import React, { useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { LockClosedIcon, SearchCircleIcon } from '@heroicons/react/solid';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Landingfooter from './Landingfooter';

function Signup() {
  const emailRef = useRef();

  const passwordConfirmRef = useRef();
  const passwordRef = useRef();
  const { currentUser, signup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const passCheck = new RegExp(
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
  );

  async function handleSubmit(e) {
    e.preventDefault();

    if (!passCheck.test(passwordRef.current.value)) {
      alert('Password Requirements not met!');
      return setError('Password Requirements not met!');
    }
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      alert('Passwords do not match');
      return setError('Passwords do not match');
    }

    try {
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      navigate('/create-profile');
    } catch (err) {
      alert('Something went wrong, please try again later!');
      setError(err);
      setLoading(false);
    }
  }

  if (!currentUser) {
    return (
      <>
        <FadeIn>
          <div className="min-h-full flex items-center justify-center py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <div>
                <div className="flex items-center justify-center">
                  <SearchCircleIcon className="text-primary mr-2 w-10 h-10" />
                  <h1 className="text-center font-extrabold font-[Poppins] text-2xl md:text-4xl text-primary my-2 ">
                    Canada Job Leads
                  </h1>
                </div>

                <h2 className="mt-6 text-center text-xl md:text-2xl font-bold text-gray-900">
                  Create an account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Already have an account?{' '}
                  <Link
                    to="/"
                    className="font-bold text-primary hover:text-red-900"
                  >
                    Sign In
                  </Link>
                </p>
              </div>
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="rounded-t-md appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Email address "
                      ref={emailRef}
                    />
                  </div>

                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Password "
                      ref={passwordRef}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password-confirm"
                      name="password-confirm"
                      type="password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Password Confirmation "
                      ref={passwordConfirmRef}
                    />
                  </div>
                </div>
                <ul className="text-sm px-3  list-disc list-inside ">
                  <p className="font-bold">Password Requirements:</p>
                  <li>At least one upper case English letter,</li>
                  <li>At least one lower case English letter,</li>
                  <li>At least one digit,</li>
                  <li>At least one special character (#?!@$%^&*-), </li>
                  <li>Minimum eight characters in length.</li>
                </ul>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      required
                      className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                    >
                      You have read the{' '}
                      <Link
                        to="/terms"
                        className="font-bold text-primary hover:underline"
                      >
                        terms & conditions
                      </Link>{' '}
                      and agree to them.
                    </label>
                  </div>
                </div>

                <div>
                  <button
                    disabled={loading}
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-red-900 ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon
                        className="h-5 w-5 text-red-400 group-hover:text-red-200 ease-in duration-100"
                        aria-hidden="true"
                      />
                    </span>
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
        </FadeIn>
        <Landingfooter />
      </>
    );
  }

  return (
    <>
      <p className="mt-6 text-center text-xl md:text-2xl font-bold text-gray-900">
        You are already logged in!
      </p>
      <Link to="/dashboard">
        <button className=" mt-4 flex justify-center py-2 px-4 mx-auto border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-red-900 ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Go to Dashboard
        </button>
      </Link>
      <Landingfooter />
    </>
  );
}

export default Signup;
