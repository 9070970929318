import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { client } from '../client';
import { clientNoCdn } from '../clientNoCdn';
import { DashHeader, Footer, LoadingOverlay, Signin } from '../components';
import { useAuth } from '../contexts/AuthContext';
import { creditsQuery } from '../utils/Data';
import { loadStripe } from '@stripe/stripe-js';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

function BuyCredits() {
  const { currentUser, signout } = useAuth();
  const navigate = useNavigate();
  const [credits, setCredits] = useState();
  const [loading, setLoading] = useState(true);

  // values for packages
  const buy1 = 'CJL-BUY-01';
  const buy3 = 'CJL-BUY-03';
  const buy10 = 'CJL-BUY-10';
  const buy20 = 'CJL-BUY-20';

  useEffect(() => {
    clientNoCdn
      .fetch(creditsQuery(currentUser?.uid))
      .then((res) => {
        setCredits(res[0].postCredits || 0);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [currentUser]);

  function handleSignout(e) {
    try {
      signout();
      navigate('/');
    } catch {
      alert('Error: Failed to Sign out.');
    }
  }

  async function proceedPayment(e) {
    e.preventDefault();
    setLoading(true);
    const checkedSelection = document.querySelector(
      'input[name="buyCredits"]:checked'
    ).value;

    const selectedProduct = {
      employerId: currentUser.uid,
      productId: checkedSelection,
      email: currentUser.email,
    };

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
    const { data } = await axios.post(
      '/.netlify/functions/stripeCall',
      selectedProduct
    );
    await stripe.redirectToCheckout({ sessionId: data.id });
  }

  if (loading) {
    return <LoadingOverlay />;
  }
  if (!currentUser) {
    return <Signin />;
  } else {
    return (
      <>
        <DashHeader handle={handleSignout} />
        <div className="sm:mx-4 px-4 lg:mx-32 ">
          <div className="px-4 sm:mx-4 flex flex-col sm:flex-row justify-center lg:justify-end items-center mt-4 mb-8">
            <div>
              <p className="sm:mr-6 sm:pr-6 text-lg mr-0 mb-4 sm:mb-0 font-semibold  text-gray-800">
                <span className="font-bold mr-2 border-2 text-lg border-primary rounded px-4 py-1 text-primary ">
                  {credits}
                </span>{' '}
                Available Post Credits
              </p>
            </div>
          </div>

          <>
            <div>
              <h1 className="leading-8 mt-12 lg:mx-32 text-xl font-bold text-gray-800 text-center sm:text-left mx-4">
                <span className="underline underline-offset-8">
                  Choose your package:
                </span>
                <span className="font-medium text-primary">
                  {' '}
                  All Job Postings remain active for 91 days.
                </span>
              </h1>
              <form
                onSubmit={proceedPayment}
                className="flex flex-col sm:mx-4 px-4 lg:mx-48 my-4 py-12 min-h-[60vh]"
              >
                <div className="bg-gray-100 sm:px-8 px-4 py-4 rounded my-2 flex justify-between items-center">
                  <div className="flex items-center">
                    <input
                      required
                      value={buy1}
                      name="buyCredits"
                      id="buy1"
                      type="radio"
                      className="focus:ring-red-500 focus:border-red-500 text-primary mr-4"
                    ></input>
                    <label
                      className="font-semibold text-primary sm:text-lg"
                      htmlFor="buy-1"
                    >
                      Buy 1 Credit
                    </label>
                  </div>

                  <div>
                    <p className="font-bold text-primary sm:text-lg ">
                      $ 30.00
                    </p>
                  </div>
                </div>

                <div className="bg-gray-100 sm:px-8 px-4 py-4 rounded my-2 flex justify-between items-center">
                  <div className="flex items-center">
                    <input
                      value={buy3}
                      name="buyCredits"
                      id="buy3"
                      type="radio"
                      className="focus:ring-red-500 focus:border-red-500 text-primary mr-4"
                    ></input>
                    <label
                      className="font-semibold text-primary sm:text-lg"
                      htmlFor="buy-3"
                    >
                      Buy 3 Credits
                    </label>
                  </div>

                  <div className="flex space-x-2">
                    <p className="text-primary sm:text-lg ">(10% Savings)</p>
                    <p className="font-bold text-primary sm:text-lg ">
                      $ 81.00
                    </p>
                  </div>
                </div>

                <div className="bg-gray-100 sm:px-8 px-4 py-4 rounded my-2 flex justify-between items-center">
                  <div className="flex items-center">
                    <input
                      value={buy10}
                      name="buyCredits"
                      id="buy10"
                      type="radio"
                      className="focus:ring-red-500 focus:border-red-500 text-primary mr-4"
                    ></input>
                    <label
                      className="font-semibold text-primary sm:text-lg"
                      htmlFor="buy-10"
                    >
                      Buy 10 Credits
                    </label>
                  </div>

                  <div className="flex space-x-2">
                    <p className="text-primary sm:text-lg ">(20% Savings)</p>
                    <p className="font-bold text-primary sm:text-lg ">
                      $ 240.00
                    </p>
                  </div>
                </div>

                <div className="bg-gray-100 sm:px-8 px-4 py-4 rounded my-2 flex justify-between items-center">
                  <div className="flex items-center">
                    <input
                      value={buy20}
                      name="buyCredits"
                      id="buy20"
                      type="radio"
                      className="focus:ring-red-500 focus:border-red-500 text-primary mr-4"
                    ></input>
                    <label
                      className="font-semibold text-primary sm:text-lg"
                      htmlFor="buy-20"
                    >
                      Buy 20 Credits
                    </label>
                  </div>

                  <div className="flex space-x-2">
                    <p className="text-primary sm:text-lg ">(30% Savings)</p>
                    <p className="font-bold text-primary sm:text-lg ">
                      $ 420.00
                    </p>
                  </div>
                </div>

                <div className="sm:px-8 px-4 py-4 rounded my-2 flex justify-between items-center">
                  <div className="flex flex-col">
                    <p className="font-bold text-gray-900 ">
                      <span className="underline">Note:</span> All purchases
                      made are final and are not eligible for any refund.
                    </p>

                    <div className="flex mt-4 items-center">
                      <input
                        required
                        type="checkbox"
                        className="rounded focus:ring-red-500 focus:border-red-500 text-primary mr-4"
                      ></input>
                      <label
                        className="font-semibold text-primary "
                        htmlFor="buy-50"
                      >
                        I agree to the terms and conditions.
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="mt-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-primary hover:bg-red-900 ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Proceed to Pay
                </button>
              </form>
            </div>
          </>

          <Footer />
        </div>
      </>
    );
  }
}

export default BuyCredits;
