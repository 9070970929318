import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Signin } from "../components";
import { useAuth } from "../contexts/AuthContext";

function NoAccess() {
  const { currentUser, signout } = useAuth();
  const history = useNavigate()
  if (!currentUser) {
    return <Signin />;
  } else {
    return (
      <>
        <div className="flex justify-center px-4 py-16 lg:py-60">
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationCircleIcon
                    className="h-6 w-6 text-primary"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Invalid Request.
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      You do not have sufficient permissions to edit this job. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              
                <button
                onClick={() => history(-1)}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Go Back
                </button>

                <Link to="/dashboard">
                <button
                onClick={() => history(-1)}
                  type="button"
                  className="sm:mt-0 mt-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Dashboard
                </button>
                </Link>
              
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NoAccess;
