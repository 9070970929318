import { SearchCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import ab from '../assets/ab.ico';
import able from '../assets/able.ico';
import youth from '../assets/youth.ico';
import refugee from '../assets/refugee.png';

function Landingfooter() {
  return (
    <>
      <div className="lg:flex fixed bottom-10 left-5 right-5 hidden items-center justify-center px-4 mt-24 ">
        <div className="  md:flex lg:ml-0 md:px-6 ">
          <a
            href="https://www.newjobleads.ca"
            className="flex flex-row items-center"
          >
            <SearchCircleIcon
              className="h-6 w-6 text-[#48bb78] "
              aria-hidden="true"
            />
            <p className="text-[#48bb78] font-bold font-[Poppins] ml-2 text-lg">
              Newjobleads
            </p>
          </a>
        </div>
        <div className="  md:flex lg:ml-0 md:px-6 ">
          <a
            href="https://www.aboriginaljobleads.ca"
            className="flex flex-row items-center"
          >
            <img src={ab} alt="AboriginalJobLeads" className="w-6 h-6" />
            <p className="text-yellow-500 font-bold font-[Poppins] ml-2 text-lg">
              AboriginalJobLeads
            </p>
          </a>
        </div>
        <div className="  md:flex lg:ml-0 md:px-6 ">
          <a
            href="https://www.youthjobleads.ca"
            className="flex flex-row items-center"
          >
            <img src={youth} alt="YouthJobLeads" className="w-6 h-6" />
            <p className="text-indigo-500 font-bold font-[Poppins] ml-2 text-lg">
              YouthJobLeads
            </p>
          </a>
        </div>
        <div className="  md:flex lg:ml-0 md:px-6 ">
          <a
            href="https://www.refugeejobleads.ca"
            className="flex flex-row items-center"
          >
            <img src={refugee} alt="RefugeeJobLeads" className="w-6 h-6" />
            <p className="text-[#ff8000] font-bold font-[Poppins] ml-2 text-lg">
              RefugeeJobLeads
            </p>
          </a>
        </div>
        <div className=" md:flex lg:ml-0 md:px-6 ">
          <a
            href="https://www.ablejobleads.ca"
            className="flex flex-row items-center"
          >
            <img src={able} alt="AbleJobLeads" className="w-6 h-6" />
            <p className="text-blue-600 font-bold font-[Poppins] ml-2 text-lg">
              AbleJobLeads
            </p>
          </a>
        </div>
      </div>
    </>
  );
}

export default Landingfooter;
